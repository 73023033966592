<template>
  <div class="user-box feed-back">
    <search-form @search="searchList" @add="showAdd = true"></search-form>
    <feed-table :list="list"></feed-table>
    <!-- 分页管理 -->
    <el-pagination
      background
      :page-sizes="pageSizes"
      :page-size="10"
      :current-page="currentPage"
      layout="total, sizes, prev, pager, next"
      :total="page.allNum"
      @size-change="changeSize"
      @current-change="changePage"
    ></el-pagination>
    <add-form :is-show="showAdd" @cancel="showAdd = false" @submit="submitForm"></add-form>
  </div>
</template>

<script>
import searchForm from './components/search-form';
import feedTable from './components/feed-table';
import addForm from './components/add-form';

import service from './api';
import pagination from '@/mixins/pagination';
export default {
  mixins: [pagination],
  data() {
    return {
      list: [],
      page: {},
      showAdd: false,
      searchData: {}
    };
  },
  components: {
    searchForm,
    feedTable,
    addForm
  },
  methods: {
    // 初始化
    init() {
      let initParam = { pageNum: 1 };
      this.getList(initParam);
    },
    // 获取反馈数据
    getList(params) {
      // 切换当前页码
      let { pageNum = 1 } = params;
      this.currentPage = pageNum;

      return service.getList(params).then(res => {
        let { list = [], page = {} } = res;
        list.forEach((item, index) => {
          item.tempId = (params.pageNum - 1) * page.pageSize + index + 1;
        });
        this.list = list;
        this.page = page;
      });
    },
    // 搜索
    searchList(data) {
      let params = { pageNum: 1, ...data };
      this.searchData = data;
      return this.getList(params);
    },
    // 提交反馈
    submitForm(data) {
      return service.add(data).then(res => {
        this.showAdd = false;
        this.$notify({ message: '提交成功', type: 'success', offset: 400 });
        this.init();
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.feed-back {
}
</style>