<template>
  <div class="edit">
    <el-dialog
      title="提交反馈"
      :visible.sync="isShow"
      :width="width"
      :show-close="false"
      :modal="false"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-width="100px"
        class="form"
        hide-required-asterisk
      >
        <el-form-item label="反馈类型" prop="type">
          <el-select v-model="form.type" placeholder="选择状态">
            <el-option
              v-for="item in typeOpts"
              :key="item.id"
              :label="item.value"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="反馈内容" prop="content">
          <el-input autosize type="textarea" v-model="form.content" placeholder="请输入反馈内容"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import service from '../api';
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: { type: null, content: '' },
      typeOpts: [],
      rules: {
        type: [{ required: true, message: '请选择反馈类型', trigger: 'change' }],
        content: [{ required: true, message: '请填写内容', trigger: 'blur' }]
      },
      width: '40%'
    };
  },
  methods: {
    cancel() {
      this.$emit('cancel');
      this.$refs['form'].resetFields();
    },
    submit() {
      let check = false;
      this.$refs['form'].validate(res => (check = res));
      if (!check) return;
      this.$emit('submit', this.form);
      this.$refs['form'].resetFields();
    },
    // 获取反馈类型选项
    getTypeOpts() {
      return service.getType().then(res => {
        let { children = [] } = res;
        this.typeOpts = children.map(item => {
          return { id: item.id, value: item.val };
        });
      });
    }
  },
  mounted() {
    this.getTypeOpts();
  },
  created(){
    if (document.documentElement.clientWidth < 480) {
      this.width = '90%'
    }
  }
};
</script>

<style lang="scss" scoped>
.edit {
  .form {
    width: 70%;
    text-align: left;
    margin: 0 auto;
  }
}
</style>

